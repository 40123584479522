<template>
  <div class="container">
    <div class="header">
      <div class="search">
        <div class="search-first">
          <div class="search-item">
            <div class="search-item-name">订单编号：</div>
            <el-input
              placeholder="请输入订单编号"
              prefix-icon="el-icon-search"
              v-model="search.code"
              @keydown.native.enter="handleSearch"
            >
            </el-input>
          </div>
          <div class="search-item">
            <div class="search-item-name">客户昵称：</div>
            <el-input
              placeholder="请输入客户昵称"
              prefix-icon="el-icon-search"
              v-model="search.nickName"
              @keydown.native.enter="handleSearch"
            >
            </el-input>
          </div>
          <div class="search-item">
            <div class="search-item-name">客户ID：</div>
            <el-input
              placeholder="请输入客户ID"
              prefix-icon="el-icon-search"
              v-model="search.memberId"
              @keydown.native.enter="handleSearch"
            >
            </el-input>
          </div>
          <div>
            <el-button type="primary" plain @click="reset">重置</el-button>
            <el-button
              type="primary"
              icon="el-icon-search"
              @click="handleSearch"
              >搜索</el-button
            >
          </div>
        </div>
        <div class="search-second">
          <div class="search-item">
            <div class="search-item-name">商品名称：</div>
            <el-input
              placeholder="请输入商品名称"
              prefix-icon="el-icon-search"
              v-model="search.goodsName"
              @keydown.native.enter="handleSearch"
            >
            </el-input>
          </div>
          <div class="search-item">
            <div class="search-item-name">商品货号：</div>
            <el-input
              placeholder="请输入商品货号"
              prefix-icon="el-icon-search"
              v-model="search.productCode"
              @keydown.native.enter="handleSearch"
            >
            </el-input>
          </div>
          <div class="search-item">
            <div class="search-item-name">收件人：</div>
            <el-input
              placeholder="请输入收件人名称"
              prefix-icon="el-icon-search"
              v-model="search.contactName"
              @keydown.native.enter="handleSearch"
            >
            </el-input>
          </div>
          <div class="search-item">
            <div class="search-item-name">收件人电话：</div>
            <el-input
              placeholder="请输入收件人电话"
              prefix-icon="el-icon-search"
              v-model="search.mobileNumber"
              @keydown.native.enter="handleSearch"
            >
            </el-input>
          </div>
        </div>
        <div class="search-third">
          <div class="search-item">
            <div class="search-item-name">板块名称：</div>
            <el-cascader
              v-model="search.plate"
              :options="plates"
              :props="{ checkStrictly: true, label: 'name', value: 'id' }"
              clearable
              filterable
              @change="handleSearch"
            ></el-cascader>
          </div>
          <div class="search-item">
            <div class="search-item-name">类目名称：</div>
            <el-cascader
              v-model="search.category"
              :options="categories"
              :props="{ checkStrictly: true, label: 'name', value: 'id' }"
              clearable
              filterable
              @change="handleSearch"
            ></el-cascader>
          </div>
          <div class="search-item">
            <div class="search-item-name">下单时间：</div>
            <el-date-picker
              v-model="search.range"
              type="daterange"
              style="width: 280px"
              range-separator="至"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
              @change="handleSearch"
            >
            </el-date-picker>
          </div>
        </div>
      </div>
      <div class="operation">
        <el-button type="primary" plain @click="batchSend">批量发货</el-button>
        <el-button type="primary" plain @click="batchPrint"
          >批量打印小票</el-button
        >
      </div>
    </div>

    <div class="content">
      <Tabs @onChange="handleTabChange" type="bulk" />
      <el-table
        border
        :data="orders"
        :span-method="objectSpanMethod"
        style="width: 100%"
        @selection-change="handleSelectionChange"
      >
        <el-table-column prop="name" min-width="260" label="商品信息">
          <template slot-scope="scope">
            <div class="good">
              <img
                v-if="scope.row.mainImgUrl"
                :src="scope.row.mainImgUrl"
                alt=""
              />
              <div class="info">
                <div class="info-name">{{ scope.row.name }}</div>
                <div class="info-sku">货号：{{ scope.row.productCode }}</div>
                <div class="info-sku" v-if="status === 'wait'">
                  已累计{{ getTotal(scope.row.pid, orders) }}件，
                  <span class="info-sku-link" @click="handleSku(scope.row.pid)"
                    >查看所有规格</span
                  >
                </div>
              </div>
            </div>
          </template>
        </el-table-column>
        <el-table-column type="selection" width="55" />
        <el-table-column
          prop="code"
          label="订单编号"
          width="160"
          show-overflow-tooltip
        >
        </el-table-column>
        <el-table-column prop="skuList" label="商品规格" show-overflow-tooltip>
          <template slot-scope="scope">
            <div v-for="item in scope.row.skuList" :key="item.skuName">
              <span>{{ item.skuName }} * {{ item.quantity }}</span>
            </div>
          </template>
        </el-table-column>
        <el-table-column
          prop="goodsAmount"
          label="总金额"
          show-overflow-tooltip
        >
          <template slot-scope="scope">
            <div class="money">
              <div class="money-total">
                ¥{{ _pennyToYuan(scope.row.goodsAmount) }}
              </div>
              <div>运费：¥{{ _pennyToYuan(scope.row.freight) }}</div>
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="contactName" label="收件人信息" min-width="180">
          <template slot-scope="scope">
            <div class="member">
              <div class="member-name">姓名：{{ scope.row.contactName }}</div>
              <div class="member-id">
                手机号码: {{ scope.row.mobileNumber }}
              </div>
              <div class="member-address">地址：{{ scope.row.address }}</div>
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="status" label="订单状态" show-overflow-tooltip>
          <template slot-scope="scope">
            {{
              !scope.row.check && scope.row.status === 1
                ? "待结单"
                : statusMap[scope.row.status]
            }}
          </template>
        </el-table-column>
        <el-table-column prop="orderTime" label="订单时间">
          <template slot-scope="scope">
            <div>
              <div>下单：{{ scope.row.createdDate }}</div>
              <div>付款：{{ scope.row.paySuccessTime }}</div>
            </div>
          </template>
        </el-table-column>
        <el-table-column
          prop="payRequestTime"
          label="用户信息"
          show-overflow-tooltip
        >
          <template slot-scope="scope">
            <div class="user">
              <img v-if="scope.row.imgUrl" :src="scope.row.imgUrl" alt="" />
              <div class="user-code">{{ scope.row.nickName }}</div>
            </div>
          </template>
        </el-table-column>
        <el-table-column
          prop="sellerRemark"
          label="备注"
          show-overflow-tooltip
        >
        </el-table-column>
        <el-table-column
          prop="deliveryCode"
          label="快递信息"
          min-width="150"
          show-overflow-tooltip
        >
          <template slot-scope="scope">
            <div class="delivery">
              <div>公司：{{ scope.row.deliveryCompany }}</div>
              <div>单号：{{ scope.row.deliveryCode }}</div>
              <div v-if="scope.row.deliveryCode">
                <el-button type="text" @click="getExpressDetail(scope.row)"
                  >物流查询</el-button
                >
              </div>
            </div>
          </template>
        </el-table-column>
        <el-table-column
          align="center"
          prop="operation"
          min-width="100"
          label="操作"
        >
          <template slot-scope="scope">
            <div>
              <el-button type="text" @click="detail(scope.row.id)"
                >详情</el-button
              >
              <el-button
                type="text"
                v-if="!scope.row.check && scope.row.status === 1"
                @click="statement(scope.row.id)"
                >结单</el-button
              >
              <el-button
                type="text"
                v-if="scope.row.check && scope.row.status === 1"
                @click="send(scope.row.id)"
                >发货</el-button
              >
              <el-dropdown size="mini">
                <span class="el-dropdown-link">
                  <span class="dropdown-name">更多操作</span>
                  <i class="el-icon-arrow-down el-icon--right"></i>
                </span>
                <el-dropdown-menu slot="dropdown" on>
                  <el-dropdown-item
                    v-if="scope.row.status === 1"
                    @click.native="cancelOrder(scope.row.id)"
                    >取消</el-dropdown-item
                  >
                  <el-dropdown-item
                    v-if="scope.row.check && scope.row.status === 1"
                    @click.native="selectSend(scope.row.id)"
                    >自定义发货</el-dropdown-item
                  >
                  <el-dropdown-item
                    v-if="scope.row.status === 1"
                    @click.native="dummySend(scope.row.id)"
                    >虚拟发货</el-dropdown-item
                  >
                  <el-dropdown-item
                    v-if="scope.row.status === 1"
                    @click.native="changeAddress(scope.row.id)"
                    >修改收货信息</el-dropdown-item
                  >
                  <el-dropdown-item
                    v-if="scope.row.status === 2"
                    @click.native="updateOrderNum(scope.row.id, scope.row.invented)"
                    >修改快递单号</el-dropdown-item
                  >
                  <el-dropdown-item @click.native="addRemark(scope.row.id)"
                    >添加备注</el-dropdown-item
                  >
                  <!-- <el-dropdown-item @click.native="printOrder">打印面单</el-dropdown-item> -->
                </el-dropdown-menu>
              </el-dropdown>
            </div>
          </template>
        </el-table-column>
      </el-table>
      <div class="export">
        <el-button type="primary" plain @click="exportData">导出</el-button>
      </div>
    </div>

    <div class="pagination">
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="currentPage"
        :page-sizes="pageOptions"
        :page-size="pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
      >
      </el-pagination>
    </div>

    <RemarkModal
      :visible="visibleRemarkModal"
      @onConfirm="handleAddRemark"
      @onCancel="visibleRemarkModal = false"
    />

    <ExpressModal
      :data="expressData"
      :visible="visibleExpressModal"
      @onCancel="visibleExpressModal = false"
    />

    <CancelModal
      :visible="visibleCancelModal"
      @onConfirm="handleCancelOrder"
      @onCancel="visibleCancelModal = false"
    />

    <SkuModal
      :visible="skuVisible"
      :data="skuData"
      @onCancel="skuVisible = false"
    />

    <ExpressSelectModal
      :visible="showChangeExpress"
      :orderId="currentOrderId"
      :type="changeExpressType"
      @onCancel="showChangeExpress = false"
      @confirm="confirmSelected"
    ></ExpressSelectModal>

    <AddressModal :visible="showChangeReceive" :orderId="currentOrderId" @onCancel="showChangeReceive = false" @confirm="confirmAddress"></AddressModal>

    <DummyModal v-if="showdummy" :orderId="currentOrderId" :type="currentDummyType" @onCancel="showdummy = false" @confirm="confirmDummy"></DummyModal>
  </div>
</template>

<script>
import Tabs from './tabs'
import * as R from 'ramda'
import moment from 'moment'
import * as orderService from '@/api/order'
import * as categoryService from '@/api/category'
import * as plateService from '@/api/plate'
import { statusMap, cancelTypeMap, getTotal } from './common.js'
import RemarkModal from './remark'
import CancelModal from './cancel'
import SkuModal from './sku'
import ExpressModal from './express'
import ExpressSelectModal from './expressSelect.vue'
import { downloadFile } from '@/utils/tools'
import { errorMessage } from '@/utils/validator'
import AddressModal from './addressModal.vue'
import DummyModal from './dummyModal.vue'

const init = {
  code: null,
  productCode: null,
  nickName: null,
  memberId: null,
  mobileNumber: null,
  contactName: null,
  goodsName: null,
  range: ['', ''],
  category: null,
  plate: null
}

let selectSpanRecord = {}

export default {
  components: {
    Tabs,
    RemarkModal,
    CancelModal,
    SkuModal,
    ExpressModal,
    ExpressSelectModal,
    AddressModal,
    DummyModal
  },
  data () {
    return {
      search: R.clone(init),
      orders: [],
      categories: [],
      plates: [],
      selected: [],
      status: 'all',
      pageOptions: [10, 20, 50, 100, 200],
      total: 0,
      pageSize: 10,
      currentPage: 1,
      selectedId: null,
      visibleRemarkModal: false,
      visibleCancelModal: false,
      visibleExpressModal: false,
      expressData: [],
      skuVisible: false,
      skuData: null,
      statusMap,
      cancelTypeMap,
      currentOrderId: 0, // 当前订单号
      showChangeExpress: false, // 是否显示换快递的弹框
      changeExpressType: 1, // 弹出修改快递时的类型，1：未发货时自定义发货。2：已发货时修改快递。
      showChangeReceive: false, // 是否显示换收货地址的弹框
      showdummy: false, // 虚拟发货弹框是否显示
      currentDummyType: 'new' // 虚拟发货的类型 发货、修改发货
    }
  },
  created () {
    this.fetchData()
    this.fetchCategories()
    this.fetchPlates()
  },
  methods: {
    getTotal,
    fmtDate (date) {
      return moment(date).format('YYYY-MM-DD HH:mm:ss')
    },
    fmt (date) {
      return moment(date).format('YYYY-MM-DD')
    },
    async fetchData (page = this.currentPage, size = this.pageSize, search = this.search) {
      try {
        const query = search
          ? {
            code: search.code,
            productCode: search.productCode,
            nickName: search.nickName,
            memberId: search.memberId,
            goodsName: search.goodsName,
            contactName: search.contactName,
            mobileNumber: search.mobileNumber,
            plateId: search.plate,
            categoryId: search.category,
            startDate:
                search.range && search.range[0]
                  ? this.fmt(search.range[0])
                  : null,
            endDate:
                search.range && search.range[1]
                  ? this.fmt(search.range[1])
                  : null,
            pageNo: page - 1,
            pageSize: size
          }
          : {
            pageNo: page - 1,
            pageSize: size
          }
        query.status = this.status === 'all' ? null : this.status
        if (this.status === 'wait') {
          query.status = '1'
          query.check = 0
        } else if (this.status === '1') {
          query.check = 1
        }
        const res = await orderService.bulkOrderList(query)
        const target = []
        res.records.forEach(o => {
          o.orderList.forEach(item => {
            const newData = {
              pid: o.id,
              mainImgUrl: o.mainImgUrl,
              name: o.name,
              productCode: o.productCode
            }
            const record = R.merge(R.omit(['orderList'], newData), item)
            target.push(record)
          })
        })
        this.orders = target
        this.total = res.total
      } catch (err) {
        // handle error
      }
    },
    async fetchPlates () {
      try {
        const res = await plateService.list()
        this.plates = res.list
      } catch (err) {
        this.$$notify.error('获取板块列表失败！')
      }
    },
    async fetchCategories () {
      try {
        const res = await categoryService.list()
        this.categories = res.list
      } catch (err) {
        this.$$notify.error('获取类目列表失败！')
      }
    },
    handleSku (id) {
      this.skuVisible = true
      const target = this.orders.filter(o => o.pid === id)
      const map = R.pipe(
        R.pluck('skuList'),
        R.flatten(),
        R.groupBy(R.prop('skuName'))
      )(target)
      const arr = Object.keys(map).map(key => {
        const count = map[key].reduce((a, c) => {
          a += c.quantity
          return a
        }, 0)
        return {
          quantity: count,
          skuName: key
        }
      })
      this.skuData = arr
    },
    handleSearch () {
      this.fetchData()
    },
    handleTabChange (tab) {
      this.status = tab.key
      this.currentPage = 1
      this.fetchData()
    },
    handleSelectionChange (val) {
      this.selected = val
    },
    async statement (id) {
      try {
        await orderService.statement(id)
        this.fetchData()
        this.$notify.success({ title: '结单成功' })
      } catch (err) {
        this.$notify.error({ title: '结单失败' })
      }
    },
    detail (id) {
      this.$router.push(`/order/detail/${id}`)
    },
    async send (id) {
      try {
        const res = await orderService.send(id)
        if (res && res.expressErrorMsg) {
          this.$notify.error({ title: res.expressErrorMsg })
          return
        }
        this.fetchData()
        this.$notify.success({ title: '发货成功' })
      } catch (err) {
        const message = errorMessage(err) || '发货失败'
        this.$notify.error({ title: message })
      }
    },
    async batchSend () {
      try {
        const ids = this.selected.map(o => o.id).join(',')
        const res = await orderService.batchSend(ids)
        if (res && res.expressErrorMsg) {
          this.$notify.error({ title: res.expressErrorMsg })
          return
        }
        this.fetchData()
        this.$notify.success({ title: '批量发货成功' })
      } catch (err) {
        const message = errorMessage(err) || '批量发货失败'
        this.$notify.error({ title: message })
      }
    },
    async batchPrint () {
      try {
        const ids = this.selected.map(o => o.id).join(',')
        await orderService.printReceipts(ids)
        this.$notify.success({ title: '打印成功' })
      } catch (error) {
        this.$notify.error({ title: '批量打印小票失败' })
      }
    },
    addRemark (id) {
      this.visibleRemarkModal = true
      this.selectedId = id
    },
    async handleAddRemark (data) {
      try {
        await orderService.addRemark(this.selectedId, data)
        this.$notify.success({ title: '添加备注成功' })
        this.fetchData()
      } catch (err) {
        this.$notify.error({ title: '添加备注失败' })
      }
      this.visibleRemarkModal = false
    },
    cancelOrder (id) {
      this.visibleCancelModal = true
      this.selectedId = id
    },
    async handleCancelOrder (data) {
      try {
        await orderService.cancelOrder(this.selectedId, data)
        this.$notify.success({ title: '取消订单成功' })
        this.fetchData()
      } catch (err) {
        this.$notify.error({ title: '取消订单失败' })
      }
      this.visibleCancelModal = false
    },
    selectSend (id) {
      this.currentOrderId = id
      this.changeExpressType = 1
      this.showChangeExpress = true
    },
    dummySend (id) {
      this.currentOrderId = id
      this.currentDummyType = 'new'
      this.showdummy = true
    },
    changeAddress (id) {
      this.currentOrderId = id
      this.showChangeReceive = true
    },
    updateOrderNum (id, invented) {
      if (invented) {
        // 虚拟发货修改物流信息
        this.currentOrderId = id
        this.currentDummyType = 'edit'
        this.showdummy = true
      } else {
        // 正常发货修改物流信息
        this.currentOrderId = id
        this.changeExpressType = 2
        this.showChangeExpress = true
      }
    },
    async getExpressDetail (data) {
      try {
        const res = await orderService.getExpressDetail(data.id)
        if (res) {
          this.expressData = res.reverse()
          this.visibleExpressModal = true
        } else {
          this.$notify.error('获取物流信息失败')
        }
      } catch (err) {
        const message = err.message || '获取物流信息失败'
        this.$notify.error(message)
      }
    },
    handleSizeChange (val) {
      this.pageSize = val
      this.fetchData()
    },
    handleCurrentChange (val) {
      this.currentPage = val
      this.fetchData()
    },
    reset () {
      this.search = R.clone(init)
      this.fetchData()
    },
    exportData () {
      const page = this.currentPage
      const size = this.pageSize
      const search = this.search
      const query = search
        ? {
          code: search.code,
          productCode: search.productCode,
          nickName: search.nickName,
          memberId: search.memberId,
          goodsName: search.goodsName,
          contactName: search.contactName,
          mobileNumber: search.mobileNumber,
          plateId: search.plate,
          categoryId: search.category,
          startDate:
              search.range && search.range[0]
                ? this.fmt(search.range[0])
                : null,
          endDate:
              search.range && search.range[1]
                ? this.fmt(search.range[1])
                : null,
          pageNo: page - 1,
          pageSize: size
        }
        : {
          pageNo: page - 1,
          pageSize: size
        }
      query.status = this.status === 'all' ? null : this.status
      if (this.status === 'wait') {
        query.status = '1'
        query.check = 0
      } else if (this.status === '1') {
        query.check = 1
      }
      downloadFile('/management/order/group/export-param', query)
    },
    objectSpanMethod ({ row, column, rowIndex, columnIndex }) {
      if (columnIndex === 0) {
        const rowId = row.pid ? row.pid : row.id
        if (rowIndex === 0) {
          selectSpanRecord = {}
        }
        if (selectSpanRecord[rowId]) {
          return {
            rowspan: 0,
            colspan: 0
          }
        }
        const count = R.length(
          this.orders.filter(o => o.id === rowId || o.pid === rowId)
        )
        if (count > 0) {
          selectSpanRecord[rowId] = true
          return {
            rowspan: count,
            colspan: 1
          }
        }
      }
    },
    confirmSelected () {
      this.fetchData()
    },
    confirmAddress () {
      this.showChangeReceive = false
      this.fetchData()
    },
    confirmDummy () {
      this.showdummy = false
      this.fetchData()
    }
  }
}
</script>

<style scoped lang="scss">
.container {
  width: 100%;
  text-align: left;
}

.header {
  padding: 30px;
  background-color: #ffffff;
}

.search {
  padding: 20px;
  margin: 10px auto;
  background: white;

  &-first {
    display: grid;
    grid-template-columns: 280px 280px 280px auto auto;
    grid-column-gap: 20px;
    padding-bottom: 20px;
  }
  &-second {
    border-top: 1px solid #f4f7fc;
    padding-top: 20px;
    padding-bottom: 20px;
    display: grid;
    grid-template-columns: 280px 280px 280px 280px auto;
    grid-column-gap: 20px;
  }

  &-third {
    border-top: 1px solid #f4f7fc;
    padding-top: 20px;
    display: grid;
    grid-template-columns: 280px 280px 380px auto;
    grid-column-gap: 20px;
  }

  &-item {
    display: grid;
    grid-template-columns: 100px auto;
    align-items: center;
    &-name {
      text-align: right;
    }
  }
}

.operation {
  margin-top: 20px;
}

.content {
  margin-top: 10px;
  background: #ffffff;
  padding: 10px;

  &-tab {
    padding-left: 20px;
  }
}

.good {
  display: grid;
  grid-template-columns: 80px 180px;
  grid-column-gap: 10px;

  & > img {
    display: block;
    width: 80px;
    height: 80px;
  }

  .info {
    height: 100%;
    font-size: 12px;
    display: grid;
    align-content: space-between;
    &-name {
      font-weight: 600;
      color: #7d7e8e;
    }
    &-sku {
      &-link {
        color: #1d90fbff;
        cursor: pointer;
      }
    }
  }

  .member {
    font-size: 12px;
    color: #7d7e8e;
  }
}

.user {
  text-align: center;
  > img {
    width: 40px;
    height: 40px;
    border-radius: 50%;
  }
  &-name {
    font-size: 12px;
    color: #7d7e8e;
  }
}

.delivery {
  font-size: 12px;
  color: #7d7e8e;
}

.dropdown-name {
  font-size: 12px;
  color: #409eff;
  font-weight: 500;
}

.pagination {
  margin-top: 30px;
  text-align: center;
}

.money {
  &-total {
    color: #5a5b66;
    font-weight: 600;
  }
}
.export {
  display: flex;
  justify-content: flex-end;
  margin-top: 15px;
  padding-right: 30px;
}
</style>
